import React from "react"
import Layout from "../components/_layout"
import SEO from "../components/seo"
import { Box, Flex, Text, Button } from "rebass"
import { useThemeUI } from "theme-ui"

const NotFoundPage = () => {
  const { theme } = useThemeUI()

  return (
    <Layout forceOpaqueHeader>
      <Flex
        sx={{ paddingX: [5, 10] }}
        paddingY={"10rem"}
        justifyContent={"center"}
      >
        <Box minHeight={"20vh"} maxWidth={1280} justifyItems={"center"}>
          <SEO title="404: Not found" />
          <Text
            fontSize={[4, 6, 7, "86px"]}
            style={{ lineHeight: 1.3 }}
            color={theme.colors.orange}
            fontWeight={"700"}
          >
            NOT
          </Text>
          <Text
            fontSize={[4, 6, 7, "86px"]}
            style={{ lineHeight: 1.3 }}
            color={theme.colors.primary}
            fontWeight={"700"}
          >
            FOUND
          </Text>
          <Text
            fontSize={[3, 6, 7, "22px"]}
            style={{ lineHeight: 1.3 }}
            color={theme.colors.black}
            fontWeight={"700"}
            py={8}
          >
            The page you are looking for doesn&#39;t exist...
          </Text>
          <Button
            mt={3}
            fontSize={1}
            px={5}
            onClick={() => window.history.back()}
          >
            Go back
          </Button>
        </Box>
      </Flex>
    </Layout>
  )
}

export default NotFoundPage
